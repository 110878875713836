// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// import { H1, H2, Link } from '@ui/atoms';
import styles from './NotFoundView.module.scss';

export default function NotFoundView(): JSX.Element {
  return (
    <div className={styles.NotFoundView}>
      {/* <H1 text="404" color="green" />
      <H2 text="Page Not Found" color="green" />
      <Link href="/">
        <FontAwesomeIcon className={`${styles['icon-go-back']} mr-2`} icon={faChevronLeft} />
        Go Home
      </Link> */}
      404: Not Found
    </div>
  );
}
